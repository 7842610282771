/* You can add global styles to this file, and also import other style files */
@import "~@ctrl/ngx-emoji-mart/picker";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "~@angular/material/theming";
$custom-typography: mat-typography-config(
  $font-family: "Assistant,Arial",
  $body-1: mat-typography-level(16px, 24px, 500),
  $button: mat-typography-level(16px, 24px, 500),
);
@include mat-core($custom-typography);
input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

$leadColor: #00aeee;
$clientColor: #119c99;
$testColor: #d3247e;
$baseColor: #1b3f4e;

$pinkColor: #f5587b;
$redColor: #e41749;

:root {
  --redColor: #c51f41;
  --pinkColor: #f5587b;
  --greenColor: #9ccf43;
  --darkGreenColor: #119c99;
  --darkBlueColor: #345461;
  --orangeColor: #ebb527;
  --yellowColor: #ef900c;
  --grayColor: gray;
  --lightGrayColor: #f1f1f1;

  --secondaryColor: #f72585;
  --primaryColor: #7209b7;
  --textColor: #261e33;
  --bgColor: #fbfdff;
  --btnBgColor: #eef0f2;

  --bp-largest: 75em; //1200px
  --bp-medium: 56.25em; //800px
  --bp-small: 37.5em; //600px
  --bp-smallest: 31.25em; //500px
}

$bp-largest: 75em; //1200px
$bp-medium: 56.25em; //800px
$bp-small: 37.5em; //600px
$bp-smallest: 31.25em; //500px

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
  font-weight: 400;
  overflow-x: hidden;
  height: 100%;
  font-family: "Assistant", "Arial", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

body {
  margin: 0;
  background-color: var(--bgColor);
  //font-size: 1.6rem;
  // background-image: url(./assets/auth-bg.svg);
  //background-position: 65% center;
  //background-repeat: no-repeat;
}
.page-title {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  color: var(--textColor);
}
/*round select*/
/* .mat-form-field.round-select {
  .mat-select {
    padding: 1rem 0 1rem 1rem;
    border: 1.5px solid #1b3f4e;
    border-radius: 2rem;
    box-sizing: content-box;

    &-arrow {
      margin: 0 10px;
    }
  }

  .mat-form-field-label-wrapper {
    font-size: 1.3rem;
    top: -3px;
    left: 15px;
  }
  .mat-form-field-underline {
    visibility: hidden;
    height: 0;
  }
}
.cdk-overlay-pane .mat-select-panel {
  top: -12px;
  position: absolute;
} */

//input
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;

  .edit-field {
    padding: 1.2rem 0.5rem;
    background-color: #f8f8f8;
    color: #000;
  }
}
/* .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: $leadColor !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $leadColor;
}
//errors
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  position: absolute;
  left: 20rem;
  top: 1rem;
} */

/*row item*/
.row-container {
  &__item {
    border-bottom: 1px solid #d2eceb;
    height: 70px !important;

    &__cc {
      margin-top: 1rem;
      .mat-form-field {
        margin-right: 3rem;
        input {
          border: 1px solid #000;
        }
      }
      .mat-form-field-label-wrapper {
        left: 10px !important;
        top: -5px;
      }
    }

    &--auto {
      height: auto !important;
    }

    .phone-img {
      position: relative;
      left: -10px;
    }

    .boldSpan {
      font-weight: 600;
      margin-right: 3rem;
    }

    &--action {
      margin-top: 1rem;
      div.mat-list-item-content {
        padding: 0 !important;
      }
    }
  }
}

/*Typography*/
.card-title {
  font-size: 1.7rem;
  font-weight: 600;
}
.fw-400 {
  font-weight: 400;
}

/*utilities*/

//typography
.text-center {
  text-align: center;
}
.text-right {
  text-align: right !important;
}

.w-270 {
  width: 270px !important;
  margin: 0 auto;
}
.w-23 {
  width: 23% !important;
}

/* .w-100 {
  width: 100% !important;
} */

.w-70 {
  max-width: 70rem;
}
//margins
.m-0 {
  margin: 0 !important;
}
//bottom
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}

//top
.mt-n-1 {
  margin-top: -1rem;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}

//right
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}

//left
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}

//padding
.py-1 {
  padding: 1rem 0;
}
.px-1 {
  padding: 0 1rem !important;
}
.p-0 {
  padding: 0 !important;
}

.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}

//colors
.primary-color {
  color: var(--primaryColor) !important;
}
.secondary-color {
  color: var(--secondaryColor) !important;
}

//hr
hr.hr {
  border-color: #dbdbdb;
  border-style: solid;
}

.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.d-flex-center {
  display: flex;
  align-items: center;
}
//angular input material hide placeholsder when in focus
.mat-form-field.mat-form-field-should-float .mat-form-field-label-wrapper {
  display: none;
}
.mat-form-field.mat-form-field-should-float.upgrade
  .mat-form-field-label-wrapper {
  display: unset !important;
}
//angular input number always show arroes
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
.text {
  &--lead {
    color: $leadColor;
  }
  &--client {
    color: $clientColor;
  }
  &--test {
    color: $testColor;
  }
}
.activitiesThumbList {
  ::ng-deep {
    .mat-list-base .mat-list-item {
      height: auto;
    }
  }
}
.logging--btn {
  border-radius: 2rem;
  border: 0;
  background-color: #ff8a5c;
  color: #fff;
  cursor: pointer;
  outline: none;
  margin-left: auto;
  margin-right: 15px;
  height: 30px;
  width: 90px;
  font-size: 14px;
}
.btn-submit {
  border-radius: 2rem;
  margin-top: 1rem;
  width: 100px;
  padding: 1rem 0;
  border: 0;
  color: $leadColor;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;

  &--lead {
    color: #fff;
    background-color: $leadColor;

    &:hover {
      background-color: darken($leadColor, 10%);
    }
  }
  &--client {
    color: #fff;
    background-color: $clientColor;

    &:hover {
      background-color: darken($clientColor, 10%);
    }
  }
  &--test {
    color: #fff;
    background-color: $testColor;

    &:hover {
      background-color: darken($testColor, 10%);
    }
  }
}
.btn-cancel {
  border-radius: 2rem;
  margin-top: 1rem;
  width: 100px;
  padding: 1rem 0;
  border: 0;
  color: $leadColor;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;

  &--lead {
    color: $leadColor;
  }
  &--client {
    color: $clientColor;
  }
  &--test {
    color: $testColor;
  }
}
.activities-done-btn {
  border: 1px solid red;
  border-radius: 2rem;
  //margin-top: 1rem;
  width: 100px;
  padding: 0.3rem 0;
  font-family: Segoe UI;
  color: red;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s;
}
.round-indicator {
  border: 0;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  font-family: Segoe UI;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s;
  //background-color: #00aeee;
  text-align: center;
  white-space: nowrap;
  &.test {
    border: 2px solid $testColor;
    color: $testColor;
  }
}
.activities-complete-btn {
  border: 0;
  border-radius: 1.5rem;
  //margin-top: 1rem;
  width: 120px;
  padding: 0.5rem 0;
  font-family: Segoe UI;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s;
  background-color: #00aeee;
  text-align: center;
}
.task-overdue-tab {
  border-radius: 10px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.2s;
  /* text-transform: uppercase; */
  background-color: red;
  height: 35px;
  width: 100px;
  position: absolute;
  top: 15px;
}
.task-overdue-tab-unselected {
  border-radius: 10px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.2s;
  /* text-transform: uppercase; */
  background-color: rgba(0, 0, 0, 0);
  height: 35px;
  width: 100px;
  position: absolute;
  top: 15px;
}
.btn-add-product {
  border-radius: 2rem;
  margin-top: 1rem;
  width: 100px;
  padding: 0.5rem 0;
  border: 0;
  color: #00aeee;
  font-size: 1.8rem;
  cursor: pointer;
  font-size: 15px;
  font-family: Segoe UI;
  transition: all 0.2s;

  &.lead {
    color: #fff;
    background-color: $leadColor;

    &:hover {
      background-color: darken($leadColor, 10%);
    }
  }
}
.form-error {
  margin-left: 20px;
  color: red;
}
.lead-status:hover,
.lead-status.mat-selected {
  color: white !important;
}
.lead-status1:hover ,.lead-status1.mat-selected //New Lead
{
  background-color: #00aeee !important;
}
.lead-status2:hover ,.lead-status2.mat-selected //Attempted 1
{
  background-color: #ff8a5c !important;
}
.lead-status3:hover ,.lead-status3.mat-selected //Attempted 2
{
  background-color: #ff8158 !important;
}
.lead-status4:hover ,.lead-status4.mat-selected //Attempted 3
{
  background-color: #ff7854 !important;
}
.lead-status5:hover ,.lead-status5.mat-selected //Attempted 4
{
  background-color: #ff6f51 !important;
}
.lead-status6:hover ,.lead-status6.mat-selected //Attempted 5
{
  background-color: #ff664d !important;
}
.lead-status7:hover ,.lead-status7.mat-selected //Attempted 6
{
  background-color: #ff5e49 !important;
}
.lead-status8:hover ,.lead-status8.mat-selected //Attempted 7
{
  background-color: #ff5546 !important;
}
.lead-status9:hover ,.lead-status9.mat-selected //Contant created
{
  background-color: #07a6c8 !important;
}
.lead-status10:hover ,.lead-status10.mat-selected //Relevant
{
  background-color: #119c99 !important;
}
.lead-status11:hover ,.lead-status11.mat-selected //Didn't Answer Several Times
{
  background-color: #e62e60 !important;
}
.lead-status12:hover ,.lead-status12.mat-selected// Wrong Number
{
  background-color: #dc296f !important;
}
.lead-status13:hover ,.lead-status13.mat-selected //Not Interested
{
  background-color: #d3247e !important;
}
.lead-status14:hover ,.lead-status14.mat-selected //Renewed Lead
{
  background-color: #35c1f5 !important;
}
.lead-status-select {
  font-size: 14px;
  .mat-select-value {
    color: white;
  }
}
.TableClearFix {
  padding-top: 10rem;
}
.logChangedTo {
  color: orange;
}
.sortSelected {
  color: $clientColor;
}
.clickble {
  cursor: pointer;
}
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.greenArrow {
  border: solid green;
  border-width: 0 2px 2px 0;
}
.breadCrumb {
  cursor: pointer;
  font-size: 14px;
  &.selected {
    color: $clientColor;
  }
}
.btn-rect {
  cursor: pointer;
  background-color: white;
  padding: 10px;
  &--client {
    border: 2px solid $clientColor;
    color: $clientColor;
    border-radius: 5px;
  }
}
.rect-select {
  padding: 1rem;
  border: 1.5px solid #1b3f4e;
  border-radius: 5px;
  box-sizing: content-box;
}
.rotate180 {
  -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg); /* FF */
  -o-transform: rotate(180deg); /* Opera */
  -ms-transform: rotate(180deg); /* IE9 */
  transform: rotate(180deg);
}
.paginationBtn {
  border: 0;
  background-color: #f9f9f9;
  outline: none;
  cursor: pointer;
}

.disableMenu {
  pointer-events: none;
}
.backBtn {
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 1rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--greenColor);
  color: white;
  cursor: pointer;
  // margin-bottom: 30px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    font-size: 12px;
  }
}
button:focus {
  outline: 0;
}

//------ new style-----------
.form {
  margin-top: 4rem;
  &__item {
    margin-bottom: 2rem;
    color: var(--textColor);

    &__label {
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      color: var(--textColor);
    }
    &__input {
      ::ng-deep {
        .mat-form-field {
          .toggle-password {
            transform: translateY(5px);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          text-align: right;
          width: 100%;
          color: var(--secondaryColor);
          &-wrapper {
            padding-bottom: 0;
          }
          &-flex {
            border: 1px solid var(--secondaryColor);
            border-radius: 5px;
            padding: 0 0.75em 0 0;
            height: 4.5rem;
            background-color: #fff;
          }
          &-underline {
            display: none;
          }
          &-infix {
            padding: 1em 0 0.75em 0.75em;
            border-top: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
button.btn-link {
  text-decoration: underline;
  color: var(--textColor);
  padding: 0;
}
button.mat-button.btn {
  font-size: 1.6rem;
  border-radius: 7px;
  width: 100%;
  padding: 5px;
  text-align: center;
  color: var(--textColor);

  &:disabled {
    opacity: 0.5;
    color: #fff;
  }

  mat-icon {
    color: var(--secondaryColor);
  }

  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-spinner {
      margin-right: 1rem;
      circle {
        stroke: #fff;
      }
    }
  }

  &--primary {
    color: var(--primaryColor);
  }

  &--fill {
    background-image: linear-gradient(
      to right,
      var(--primaryColor) -50%,
      var(--secondaryColor) 100%
    );
    color: #fff;
  }

  &--outline {
    border: 1px solid var(--secondaryColor);
    color: var(--secondaryColor);
  }
  &--outline-black {
    border: 1px solid var(--secondaryColor);
    color: var(--secondaryColor);
  }

  &--underline {
    text-decoration: underline;

    &:disabled {
      color: var(--secondaryColor);
    }
  }

  &--small {
    font-weight: 400;
    padding: 0 1rem;
    font-size: 1.6rem;
  }

  &--big {
    font-size: 1.8rem;
  }

  &--center {
    width: auto;
    margin: 0 auto;
    padding: 0 2rem;
  }
}

.fw-light {
  font-weight: 400;
}

.mat-snack-bar-handset .mat-snack-bar-container.update-success {
  margin-bottom: 81px;

  .mat-simple-snackbar-action {
    color: var(--primaryColor);
  }
}
.call-type {
  .mat-option {
    text-align: right;
  }
}

.mat-tooltip {
  /* your own custom styles here */
  /* e.g. */
  text-align: right;
  direction: rtl;
  font-size: 1.6rem;
  line-height: 1.4;
  padding: 1rem !important;
  max-width: 400px !important;
}
@media print {
  html,
  body {
    height: auto;
    overflow-y: visible !important;
  }
}
